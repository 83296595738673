<template>
  <div>
    <h1>Informationen zur aktuellen Situation (Click &amp; Meet):</h1>
    <p>
    Nachdem Frau Dr. Merkel am 3.3.2021 um kurz vor Mitternacht die Beschlüsse verkündete und dabei auch klar war, das Shopping per Click & Meet kommen würde, hatten wir einen extremen Anstieg der Nutzerzahlen. Damit einhergehend natürlich auch eine stetig stark ansteigende Zahl an Terminbuchungen, die über Bookingflix abgewickelt wurden.
    </p>
    <p>
    In der Folge haben wir erste Server-Kapazitäten bei unserem aktuellen Server-Hoster erweitert. Dies war jedoch nur in Grenzen möglich. Daher haben wir am Donnerstagvormittag (4.3.21) einen Spezialisten beauftragt, um Bookingflix auf eine neue Server-Struktur zu übertragen. Das ist teilweise über das Wochenende bereits erfolgreich geschehen. Die Datenbanken sollen in der aktuellen Woche übertragen werden. Neuer Server-Hoster ist dann AWS. Der Serverstandort ist Franfurt am Main. Wegen der Dringlichkeit und aufgrund der extremen Server-Last, müssen wir auf den Weltmarktführer vertrauen. Das ist derzeit AWS: <a href="https://aws.amazon.com/de/compliance/data-center/controls" target="_blank" class="flix-html-a">aws.amazon.com/de/compliance/data-center/controls</a>
    </p>
    <p>
    Auch möchten wir in Kürze weitere Test-und Impfzentren, Arztpraxen und dann im letzten Schritt auch größere Kunden bedienen. Diese müssen wir derzeit noch abweisen. Dass wir einmal in diese Situation kommen könnten, hätten wir im Februar noch nicht erwartet.
    </p>
    <p>
    Seien Sie bitte versichert, dass wir alles tun und hohe Kosten in Kauf nehmen, um diese Sondersituation zu bewältigen. Ich bin zuversichtlich, dass bis Ende der Woche die neue Server-Struktur läuft.
    Bitte halten Sie dennoch einen Plan B bereit! Ihre Kunden sollten Sie auch alternativ per Email, Telefon, SMS und/oder WhatsApp für eine alternative Terminbuchung erreichen können. Sichern Sie sehr regelmäßig die aktuell eingegangenen Termindaten, falls der jetzige Bookingflix-Server einmal nicht erreichbar sein sollte. Siehe Video dazu: <a href="https://web.bookingflix.com/support/video/13" target="_blank" class="flix-html-a">web.bookingflix.com/support/video/13</a>
    </p>
    <p>
    Wir haben übrigens auch mit dem Bundeswirtschaftsministerium Kontakt aufgenommen, um darüber Hilfe (ManPower, keine finanzielle Hilfe) bzgl. Server-Problematik vermittelt zu bekommen. Dabei habe ich auch auf andere Tool-Anbieter hingewiesen, die ggf. in ähnlicher Lage sind wie wir. Ich warte noch auf Rückmeldung ... seit Donnerstag.
    Einige andere Tool-Anbieter haben übrigens bereits einen Aufnahmestopp, wie uns Neukunden berichteten. Server von H&M und weiteren Filialisten waren bzgl. Terminbuchung nicht erreichbar, wie die Presse berichtetet. Daher nehmen wir die großen Ketten erst wieder ab dem 15.3.2021 auf, wenn der Server-Umzug komplett abgeschlossen ist. Samstagabend, ab etwa 23.00 Uhr, wird dies wohl erfolgen. Dann wird eine Terminbuchung für Ihre Kunden temporär nicht möglich sein (2-4 Stunden) Auch können Sie sich nicht in Ihren Account von Bookingflix einloggen. Sonntagmorgen sollte wieder alles stabil laufen.
    </p>
    <p>
    Aktuelle Informationen bzgl. Server-Wechsel hierzu erhalten Sie nochmal am Freitag oder Samstag über Ihren Login-Bereich (Bookingflix-Dasboard).
    </p>
    <h3>Noch ein paar persönliche Worte von mir:</h3>
    <p>
    Sehen Sie die aktuelle Situation bitte auch als Chance. Wir sind alle selbständig! Wir blicken gemeinsam nach vorne! Wir sind für unsere Kunden da! Wir lächeln! Wir werden uns behaupten und nach einiger Zeit gestärkt aus der Krise kommen :-) Und ... bleiben Sie vor allem gesund.
    </p>
    <p>
    Mit freundlichen Grüßen
    <br />
    Marcus Hanke
    </p>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>
